import React from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"

import "./homePageReset.css"
import styled from "styled-components"

const Head = loadable(() => import("../components/Head"))

const Header = loadable(() => import("../components/Header"))

const FeatureVideo = loadable(() =>
  import("../components/HomePage/FeatureVideo")
)
const CharityHomeLogo = loadable(() =>
  import("../components/HomePage/CharityLogos")
)
const EventHightLight = loadable(() =>
  import("../components/HomePage/EventHighLights")
)
const HeroTitle = loadable(() => import("../components/HomePage/HeroTitle"))

const Footer = loadable(() => import("../components/Footer"))

const HomeWapper = styled.main`
  flex: 1 0 auto;
  margin: 0 auto;
  overflow: hidden;
  animation: fadein 2.5s ease-out;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Mission = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  padding: 4rem;
  background: rgb(247, 209, 134);
  background: radial-gradient(
    circle,
    rgba(247, 209, 134, 1) 0%,
    rgba(248, 211, 138, 1) 31%,
    rgba(224, 169, 74, 1) 78%
  );

  h1 {
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
  }

  p {
    padding: 0.5rem;
    line-height: 1.5;
    font-family: "GillSans", "sans";
    font-size: 1.1rem;
    text-align: center;
  }

  @media (max-width: 960px) {
    padding: 2rem;
  }
`

const HomePage = ({ data }) => {
  return (
    <>
      <Head />
      <Header />
      <HomeWapper>
        {data.allWordpressPage.edges.map(item => {
          return (
            <div key={item.node.id}>
              {/* Feature Video Component */}
              <FeatureVideo
                featVideo={item.node.acf.feature_video.source_url}
              />
              {/* Main Title Component */}
              <HeroTitle
                title={item.node.acf.hero_title}
                heroSub={item.node.acf.hero_subtitle}
              />
              {/* Event HightLight Component */}
              <EventHightLight images={item.node.acf.media_gallery} />

              <Mission>
                <h1>Our Mission</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.node.acf.mission_text,
                  }}
                />
              </Mission>
              {/* Charity Logo for Home Component */}
              <CharityHomeLogo data={item.node.acf} />
            </div>
          )
        })}
      </HomeWapper>
      <Footer />
    </>
  )
}

export default HomePage

export const homePageQuery = graphql`
  {
    allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          content
          id
          acf {
            feature_video {
              source_url
            }
            hero_title
            hero_subtitle
            mission_text
            media_gallery {
              source_url
              wordpress_id
              title
            }
            charity_partner_title
            charity_section {
              charity_one_text
              charity_one_logo {
                title
                source_url
              }
              charity_two_text
              charity_two_logo {
                title
                source_url
              }
              charity_three_text_new
              charity_three_logo {
                title
                source_url
              }
            }
          }
        }
      }
    }
  }
`
